import React from 'react'
import { useState } from 'react'
import { TopBanner } from '../components/TopBanner'
import styled from '@emotion/styled'
import {
  DataGrid,
  GridRowsProp,
  GridColDef,
  GridValueFormatterParams,
  GridRenderCellParams,
  GridEventListener,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid'
import Alert from '@mui/material/Alert'
import { navigate } from 'gatsby'
import { Link } from 'gatsby'
import { format, isBefore, sub, parse } from 'date-fns'

import LoginIcon from '@mui/icons-material/Login'

// ***** GET results on first load, shift through fo search, fetch individual card info when selected (different)
let myCount = 0
//console.log(myCount)

const isBrowser = typeof window !== 'undefined'

const Page = () => {
  function CustomToolbar() {
    return (
      <>
        <SearchTitleContainer>
          Hae lahjakortin numerolla tai sen osalla.
        </SearchTitleContainer>
        <GridToolbarContainer>
          <GridToolbarQuickFilter
            quickFilterParser={searchInput =>
              searchInput
                .replace(/[+]/g, ' ')
                .replace(/[-]/g, ' ')
                .split(' ')
                .map(value => value.trim())
            }
            disabled={loading}
            debounceMs={300} // time before applying the new quick filter value
          />
          <GridToolbarExport />
        </GridToolbarContainer>
      </>
    )
  }

  const authToken: string =
    (isBrowser ? localStorage.getItem('myauth') : '') || ''
  myCount = myCount + 1
  //console.log('ping: ' + myCount)

  const handleOnClick: GridEventListener<'rowClick'> = params => {
    navigate('/card?code=' + params.id)
    //console.log(params)
  }

  const [cards, setCards] = useState<any[]>([])
  const [username, setUser] = useState('')

  const [origCount, setOrigCount] = useState(0)
  const [isAdmin, setAdmin] = useState(false)
  const [filterString, setFilterString] = useState('')

  const [errorStatus, setErrorStatus] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const [loading, setLoading] = useState(true)

  // TODO:
  // - Better way to supress extra fetching (now always twice)
  // Only fetch valid/usable cards? (status 1 / value > 0eur)

  // *** check how many can be fetched at a time/paging?
  // -> if not enough, make several fetches and combine?
  // **** mouse/keyboard use?

  // *** check different browsers
  // *** errorhandling for auth fail (unvalid response json)
  //console.log('testi: ' + origCount)
  if (!origCount) {
    //console.log('heps: ' + myCount)
    const res = fetch(
      //`https://europe-west1-mehilainen-fi-prod.cloudfunctions.net/getCards`, // TODO: This should be moved to .env
      //`http://localhost:5001/mehilainen-fi-prod/europe-west1/getCards`, // URL for local development
      `/getCards`,
      {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        headers: {
          authorization: authToken,
          // 'Content-Type': 'application/json',
        },
      }
    )
      .then(async response => {
        //console.log('boing: ' + { response })
        //console.log(response.status)
        if (!response.ok) {
          //console.log('ei ok ')

          const text = await response.text()
          throw new Error(text)
        }

        if (response.status !== 200 && !origCount) {
          //console.log('ei 200 ')
          if (response.status === 403) {
            const text_1 = await response.text()
            //console.log('onm 403: ' + text_1)
            throw new Error(text_1)
          }
          const text_2 = await response.text()
          //console.log('muuten: ' + text_2)
          throw new Error(text_2)
        } else setErrorStatus(false)

        //console.log(JSON.stringify(response.json(), null, 2))
        return response.json()
      })
      .then(data => {
        //console.log('HEEP' + JSON.stringify(data))
        //console.log('dataa')
        setUser(data.username)
        setAdmin(data.isadmin)

        const compareDate = data.isadmin
          ? sub(new Date(), {
              days: 5000,
            })
          : sub(new Date(), {
              days: 1,
            })
        //console.log('paiva:   ' + compareDate)
        const editedCards = data.items.map(
          item =>
            1 * item['balance'] > 0
              ? isBefore(
                  compareDate,
                  item['expired_at']
                    ? parse(
                        format(
                          new Date(item['expired_at'].replace(/-/g, '/')),
                          'dd.MM.yyyy'
                        ),
                        'dd.MM.yyyy',
                        new Date()
                      )
                    : new Date()
                )
                ? {
                    id: item['giftcard_id'],
                    code: item['code'],
                    edited: item['code'].replace(/[-]/g, ''),
                    recipient: item['template_fields'].recipient,
                    date: item['delivery_date'],
                    email: item['delivery_address'],
                    expiration: item['expired_at'],
                    balance: (1 * item['balance']).toFixed(2),
                  }
                : null
              : null
          //  :
        )

        //console.log(editedCards)
        //console.log(data.items)
        setCards(
          editedCards.filter(element => {
            return element !== null
          })
        )

        //setCards(data.items)

        setFilterString(
          data.isadmin
            ? format(
                sub(new Date(), {
                  days: 5000,
                }),
                'yyyy-MM-dd'
              )
            : format(
                sub(new Date(), {
                  days: 3,
                }),
                'yyyy-MM-dd'
              )
        )
        //console.log(data.isadmin + ' pvm: ' + filterString)
        //console.log('ennen datan tuuppaamista: ' + origCount)
        setOrigCount(data.items.length)
      })
      .catch((error: Error) => {
        //console.log(
        //  'erroria ennen: ' +
        //    (!origCount ? 'eka: ' + origCount : 'toka:' + origCount)
        //)
        !origCount ? setErrorStatus(true) : setErrorStatus(false)

        error?.message?.includes('100')
          ? setErrorMessage('Käyttäjätasosi ei riitä')
          : error?.message?.includes('200')
          ? setErrorMessage('Kirjaudu sisään')
          : setErrorMessage('Ongelma') //palauta virheviesti 1, virheviesti 2 tai geneerinen

        //console.log('haaalp: ' + error.message)
      })
      .finally(() => setLoading(false))
  }

  const tableColumns: GridColDef[] = [
    {
      headerName: 'KOODI',
      headerAlign: 'center',
      align: 'center',
      field: 'code',
      sortable: false,
      flex: 1,
    },
    {
      headerName: 'KOODI',
      headerAlign: 'center',
      align: 'center',
      field: 'edited',
      sortable: false,
      flex: 1,
    },

    {
      headerName: 'VASTAANOTTAJA',
      headerAlign: 'center',
      align: 'center',
      field: 'recipient',
      sortable: false,
      flex: 1,
    },
    {
      headerName: 'TOIMITUSPÄIVÄ',
      headerAlign: 'center',
      align: 'center',
      field: 'date',
      sortable: false,
      flex: 1,
      valueFormatter: (params: GridValueFormatterParams<string>) => {
        if (params.value == null) {
          return ''
        }

        const valueFormatted = format(
          new Date(params.value.replace(/-/g, '/')),
          'dd.MM.yyyy'
        )
        return `${valueFormatted}`
      },
    },
    {
      headerName: 'TOIMITUSOSOITE',
      headerAlign: 'center',
      align: 'center',
      field: 'email',
      sortable: false,
      flex: 1,
    },
    {
      headerName: 'VOIMASSAOLO PÄÄTTYY',
      headerAlign: 'center',
      align: 'center',
      field: 'expiration',
      sortable: true,
      flex: 1,
      renderCell: (params: GridRenderCellParams<string>) => (
        <>
          {params.value ? (
            isBefore(
              sub(new Date(), {
                days: 1,
              }),
              parse(
                format(new Date(params.value.replace(/-/g, '/')), 'dd.MM.yyyy'),
                'dd.MM.yyyy',
                new Date()
              )
            ) ? (
              format(new Date(params.value.replace(/-/g, '/')), 'dd.MM.yyyy')
            ) : (
              <React.Fragment>
                <Alert variant="outlined" severity="warning">
                  {format(
                    new Date(params.value.replace(/-/g, '/')),
                    'dd.MM.yyyy'
                  )}
                </Alert>
              </React.Fragment>
            )
          ) : (
            'arvo puuttuu'
          )}
        </>
      ),
    },

    {
      headerName: 'SALDO',
      headerAlign: 'center',
      align: 'right',
      field: 'balance',
      sortable: false,
      flex: 1,
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (params.value == null) {
          return ''
        }

        const valueFormatted = Number(params.value).toFixed(2)
        return `${valueFormatted} €`
      },
    },
  ]

  const rows: GridRowsProp = [
    cards.map(item => ({
      id: item['id'],
      code: item['code'],
      edited: item['edited'],
      recipient: item['recipient'],
      date: item['date'],
      email: item['email'],
      expiration: item['expiration'],
      balance: (1 * item['balance']).toFixed(2),
    })),
  ]
  return (
    <>
      <div>
        <TopBanner user={username} />
      </div>
      <div>
        {errorStatus ? (
          <SearchContainer>
            <Alert variant="filled" severity="error" sx={{ width: '100%' }}>
              Virhetilanne: {errorMessage}
            </Alert>

            <LinkContainer>
              <Link to="/">Palaa sisäänkirjautumissivulle</Link>
              <LoginIcon sx={{ ml: 1, color: 'black' }} />
            </LinkContainer>
          </SearchContainer>
        ) : (
          ''
        )}

        <ResultContainer>
          <DataGrid
            rows={cards}
            columns={tableColumns}
            pageSize={20}
            getRowId={row => row.id}
            autoHeight
            density="comfortable"
            disableColumnMenu={true}
            disableColumnFilter={true}
            disableColumnSelector={true}
            autoPageSize
            loading={loading}
            components={{ Toolbar: CustomToolbar }}
            onRowClick={handleOnClick}
            localeText={{
              toolbarDensityComfortable: 'Large',
              noRowsLabel: errorStatus ? 'Virhetilanne ' : 'Ei tuloksia',
              noResultsOverlayLabel: 'Ei tuloksia.',
              errorOverlayDefaultLabel: 'Tapahtui virhe.',
              toolbarExport: 'Lataa',
              toolbarExportLabel: 'Lataa',
              toolbarExportCSV: 'Lataa CSV-muodossa',
              toolbarExportPrint: 'Tulosta',
              toolbarQuickFilterPlaceholder:
                'Kirjoita hakuteksti (vähintään 3 merkkiä)',
              toolbarQuickFilterLabel: 'Hae',
              toolbarQuickFilterDeleteIconLabel: 'Tyhjennä',
              MuiTablePagination: {
                labelDisplayedRows: ({ from, to, count }) =>
                  ` sivut ${from}-${to}  /${count}`,
              },
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: 'expiration', sort: 'desc' }],
              },
              columns: {
                columnVisibilityModel: {
                  // Hide columns status and traderName, the other columns will remain visible
                  edited: false,
                  recipient: false,
                },
              },
            }}
            sx={{
              '& .MuiDataGrid-row:hover': {
                backgroundColor: '#E9FAF2',
                color: 'primary.main',
              },
              '& .MuiDataGrid-columnHeader': {
                color: 'primary.main',
              },
              '& .MuiDataGrid-columnHeaderTitle': {
                fontWeight: '700',
              },
              '& .MuiDataGrid-cell:focus-within': {
                outline: 'unset',
              },
              '& .MuiDataGrid-columnHeader:focus-within': {
                outline: 'unset',
              },
              '& .MuiDataGrid-toolbarContainer': {
                justifyContent: 'space-between',
                borderBottomWidth: '1px',
                borderBottomStyle: 'solid',
                borderBottomColor: 'rgba(224, 224, 224, 1)',
                '& .MuiInputBase-formControl': {
                  width: '400px',
                  paddingLeft: '5px',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                },

                '& .MuiInput-underline:before': { borderBottom: 'unset' },
                '& input': {
                  paddingLeft: '5px',
                },
              },
            }}
          />
        </ResultContainer>
      </div>
    </>
  )
}

export default Page

const ResultContainer = styled.div`
  max-width: 1100px;
  width: 100%;
  margin: auto;
  padding: 40px;
  text-align: center;
`
const SearchTitleContainer = styled.div`
  margin-bottom: 8px;
  margin-top: 5px;
  padding-top: 5px;
  margin-left: 10px;
  width: 100%;
  text-align: left;
  font-size: 1rem;
`

const SearchContainer = styled.div`
  width: 400px;
  margin: auto;
  padding-top: 15px;
  display: flex;
  text-align: center;
  flex-direction: column;
`

const LinkContainer = styled.div`
  margin: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  align-items: center;
  display: flex;
  color: #eef8ef;
`
